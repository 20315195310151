import "bootstrap/dist/css/bootstrap.min.css";
import WebFont from 'webfontloader';
import "./App.css";

import React, { useState, useEffect, useCallback, lazy, Suspense } from "react";
import { isMobile } from "react-device-detect";
import CookieConsent, { Cookies } from "react-cookie-consent";
import { Button, ButtonGroup } from "react-bootstrap";

// lazy import of components
const Header = lazy(() => import("./Components/Header"));
const Footer = lazy(() => import("./Components/Footer"));
const Inicio = lazy(() => import("./Components/Inicio"));
const StickyFooter = lazy(() => import("./Components/parts/StickyFooter"));

// debounce function
const debounce = (func, wait) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), wait);
  };
};

const App = () => {
  const [showCookieConsent, setShowCookieConsent] = useState(
      !Cookies.get("codere_cookie")
  );

  const [flag, setFlag] = useState(isMobile);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const handleResize = useCallback(
      debounce(() => {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }, 200),
      []
  );

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [handleResize]);

  useEffect(() => {
    setFlag(windowSize.width <= 768 || isMobile);
  }, [windowSize]);

  const cookieHandler = useCallback((isAccepted) => {
    const acceptedValue = isAccepted ? "granted" : "denied";

    console.log("New status: ", acceptedValue);
    window.gtag("consent", "update", {
      ad_storage: acceptedValue,
      ad_user_data: acceptedValue,
      ad_personalization: acceptedValue,
      analytics_storage: acceptedValue,
    });

    window.fbq("consent", isAccepted ? "grant" : "revoke");

    const oneYearFromNow = new Date();
    oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);

    Cookies.set("codere_cookie", isAccepted ? "accepted" : "rejected", {
      domain: ".codere.es",
      expires: oneYearFromNow,
    });
    setShowCookieConsent(false);
  }, []);

  return (
      <>
        <Suspense fallback={<div>Loading...</div>}>
          <Header device={flag} />
        </Suspense>
        {showCookieConsent && (
            <CookieConsent
                cookieName="codere_cookie"
                expires={365}
                buttonClasses="cookie-btn"
                buttonText=""
                overlay={false}
                disableButtonStyles
                extraCookieOptions={{ domain: ".codere.es" }}
                overlayClasses="cookie-overlay"
                containerClasses="cookie-container"
                contentClasses="cookie-content"
            >
              <div className="cookie-text">
                <h3>Configuración de cookies</h3>
                Esta página web, propiedad de Codere Online S.A.U, utiliza cookies
                propias y de terceros con la finalidad de permitir tu navegación,
                elaborar información estadística y analizar tus hábitos de
                navegación, así como mostrarte la publicidad ajustada a tus
                preferencias. Puedes hacer clic en ACEPTAR para permitir el uso de
                todas las cookies, rechazarlas todas haciendo clic en RECHAZAR, o
                elegir qué tipo de cookies deseas aceptar o rechazar, mediante la
                opción configurar cookies. Puedes obtener más información en nuestra{" "}
                <a href="https://www.codere.es/informaci%C3%B3n-legal/politica-de-cookies">
                  política de cookies
                </a>
                . Está página web está optimizada para Google Chrome, en caso de
                encontrar algún funcionamiento incorrecto por favor use el citado
                navegador. <br />
              </div>
              <ButtonGroup className="cookie-btn-group">
                <Button
                    className="gr-cookie cookie-btn"
                    onClick={() => cookieHandler(true)}
                >
                  Aceptar
                </Button>
                <Button
                    className="gr-cookie cookie-btn"
                    onClick={() => cookieHandler(false)}
                >
                  Rechazar
                </Button>
                <Button
                    className="wh-cookie cookie-btn"
                    rel="nofollow"
                    href="https://m.apuestas.codere.es/deportes/#/CookiesConsentPage"
                >
                  Configurar Cookies
                </Button>
              </ButtonGroup>
            </CookieConsent>
        )}
        <Suspense fallback={<div>Loading...</div>}>
          <Inicio flag={flag} />
        </Suspense>
        {flag && (
            <Suspense fallback={<div>Loading...</div>}>
              <StickyFooter />
            </Suspense>
        )}
        <Suspense fallback={<div>Loading...</div>}>
          <Footer device={flag} />
        </Suspense>
      </>
  );
};

export default App;
